<template>
	<div id="bottom-sheet-wrapper" />
	<router-view />
</template>

<script setup>
import useCSP from "@/views/composables/csp/useCSP.js";

const { csp } = useCSP();
csp();
</script>

<style scoped></style>
