import { useHead } from "@unhead/vue";

export default function () {
	const csp = (content = null) => {
		if ("production" !== import.meta.env.MODE) return false;

		if (!content) {
			content =
				`default-src 'self';` +
				`connect-src 'self' https://content.hotjar.io/ https://metrics.hotjar.io/ wss://ws.hotjar.com/ https://o4507300192321536.ingest.de.sentry.io/ https://sockjs-eu.pusher.com/ wss://ws-eu.pusher.com/;` +
				`script-src 'self' 'unsafe-inline' 'unsafe-eval' blob:; ` +
				`script-src-elem 'self' https://static.hotjar.com/ https://script.hotjar.com/ https://www.googletagmanager.com/; ` +
				`style-src 'self' 'unsafe-inline'; ` +
				`img-src 'self' data:;`;
		}

		useHead({
			meta: {
				"http-equiv": "Content-Security-Policy",
				content: content,
			},
		});

		return true;
	};

	return {
		csp,
	};
}
